const Yoga = () => {
    return (
        /* 
            talk about traditional yoga vs western views of yoga
            basic overview of eight limbs
            basic overview of four stages
        */
       <div>
           
       </div>
    )
}

export default Yoga